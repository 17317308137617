import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Cont } from './cont';

@Injectable({
  providedIn: 'root'
})
export class ContService {

  //baseUrl = 'http://localhost/server_api/login8';
  baseUrl = 'https://www.sebastianoweb.it/server_api/backend/';
  
  conts: Cont[];

  constructor(private http: HttpClient) { }

    store(cont: Cont): Observable<Cont[]> {
    return this.http.post(`${this.baseUrl}/regcont.php`, { data: cont })
      .pipe(map((res) => {
        this.conts.push(res['data']);
        return this.conts;
      }));
    //  catchError(this.handleError));
}

getstore(params?: {
  nome?: string;
  email?: string;
  message?: string;
  priv?: string;
}): Observable<Cont[]> {
  console.log('DETECTgoemail=>', params);
  return this.http.post<Cont[]>(
    `${this.baseUrl}/regcont.php/`,
    params
  );
}


}
