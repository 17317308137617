import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { DataserviceService } from './../../dataservice.service';
import { ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-listamanu',
  templateUrl: './listamanu.component.html',
  styleUrls: ['./listamanu.component.css']
})
export class ListamanuComponent  {
    
  data = [];
  
  config: any;
  collection = [];
  constructor(private route: ActivatedRoute, private http: HttpClient, private Router: Router, private dataService: DataserviceService) {
     // PAGINATION
     this.config = {
      currentPage: 1,
      itemsPerPage: 15,
      totalItems:0
      };
      route.queryParams.subscribe(
      params => this.config.currentPage= params['page']?params['page']:1 );
       
      for (let i = 1; i <= 100; i++) {
      this.collection.push('item ${i}');
      }
       
      }
       
      pageChange(newPage: number) {
          this.Router.navigate(['manu'], { queryParams: { page: newPage } });
        
     

     // FINE PAGINATION
    

    

  //  this.http.get('http://localhost/server_api/login8/listamanu.php').subscribe(data => {
    this.http.get('https://www.sebastianoweb.it/server_api/backend/listamanu.php').subscribe(data => {
    this.data.push(data);
    console.log(this.data);
    
    
    }, error => console.error(error));
  }


  nuovomanu() {
    this.Router.navigate(['/regmanu']);
  }




}


