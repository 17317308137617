import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { ContentComponent } from './components/shared/content/content.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SettingComponent } from './components/shared/setting/setting.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FlashMessagesModule, FlashMessagesService } from 'angular2-flash-messages';
import { ContactService } from './contact.service';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
//import { UpdatemanuComponent } from './dashboard/updatemanu/updatemanu.component';
import { ListamanuComponent } from './dashboard/listamanu/listamanu.component';
import { RegmanuComponent } from './dashboard/regmanu/regmanu.component';
import { ListabolloComponent } from './dashboard/listabollo/listabollo.component';
import { RegbolloComponent } from './dashboard/regbollo/regbollo.component';
import { RegrevComponent } from './dashboard/regrev/regrev.component';
import { ListarevComponent } from './dashboard/listarev/listarev.component';



import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertModule } from 'ngx-alerts';
import { UpdatemanuComponent } from './dashboard/updatemanu/updatemanu.component';

//import { ReactiveFormsModule } from '@angular/forms';

import { OrderModule } from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    ContentComponent,
    FooterComponent,
    SettingComponent,
    LoginComponent,
    DashboardComponent,
    ListamanuComponent,
    RegmanuComponent,
    ListabolloComponent,
    RegbolloComponent,
    RegrevComponent,
    ListarevComponent,
    UpdatemanuComponent
   
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FlashMessagesModule,
    ScrollToModule.forRoot(),
    BrowserAnimationsModule,
    BrowserModule,
    OrderModule,
    NgxPaginationModule,
    AlertModule.forRoot({maxMessages: 5, timeout: 5000, position: 'right'})
  ],
  providers: [FlashMessagesService,
    ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }




