
export class Bollo {
  id: number;
  data_giorno: string;
      data_mese: string;
      data_anno: string;
      marca: string;
      prezzo: string;
      pagato: string;
   
}









// import { Component, OnInit } from '@angular/core';

//  @Component({
//   selector: 'app-car'
//   // templateUrl: './app.component.html',
//   // styleUrls: ['./app.component.css']
//  })

// export class Bollo  { 
  
// data = [];
//   constructor(
//     data_giorno: string,
//     data_mese: string,
//     data_anno: string,
//     marca: string,
//     prezzo: string,
//     pagato: string,
//     id?:   number
//     ) {

    
//         }
//       }
