import { Component} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listarev',
  templateUrl: './listarev.component.html',
  styleUrls: ['./listarev.component.css']
})
export class ListarevComponent  {
  data = [];
  constructor(private http: HttpClient, private Router: Router) {
   // this.http.get('http://localhost/server_api/login8/listarev.php').subscribe(data => {
    this.http.get('https://www.sebastianoweb.it/server_api/backend/listarev.php').subscribe(data => {
    this.data.push(data);
    console.log(this.data);
    
    
    }, error => console.error(error));
  }


  nuovorev() {
    this.Router.navigate(['/regrev']);
  }




}
