import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Cont } from "./../../../../cont";
import { ContService } from "./../../../../cont.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AlertService } from "ngx-alerts";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
})
export class ContentComponent implements OnInit {
  title: string = "";
  registerForm: FormGroup;
  submitted = false;

  conts: Cont[];
  cont = new Cont();
  error = "";
  success = "";
  nome: string = "";
  email: string = "";
  message: string = "";
  priv: string = "";

  data = [];
  constructor(
    private http: HttpClient,
    private contService: ContService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {}

  showAlerts(): void {
    // SCELTA TIPOLOGIA ALERT
    this.alertService.info("this is an info alert");
    this.alertService.danger("this is a danger alert");
    this.alertService.success("this is a success alert");
    this.alertService.warning("this is a warning alert");

    // For html messages:
    //  this.alertService.warning({html: '<b>This message is bold</b>'});
  }

  // VALIDAZIONE MODULO CONTATTI
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      nome: ["", Validators.required],
      email: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
          Validators.maxLength(100),
        ],
      ],

      message: ["", Validators.required],
      priv: ["", Validators.required],
      //  password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  addCont() {

   // alert('PAR=>'+JSON.stringify(par));

    this.submitted = true;
    // alert("DATI SALVATI CORRETTAMENTE");

    // stop the process here if form is invalid
    if (this.registerForm.invalid) {
      //alert('MANCANO ALCUNI DATI !');

      this.alertService.warning({
        html: "<b>ERRORE DI COMPILAZIONE NEL MODULO !</b>",
      });
      return;
    }

    //alert('SUCCESS!!');
    this.alertService.success({
      html: "<b>MESSAGGIO INVIATO, Risponderò il prima possibile, grazie.</b>",
    });

    this.error = "";
    this.success = "";



    const par = {
      nome: this.registerForm.value.nome,
      email: this.registerForm.value.email,
      message: this.registerForm.value.message,
      priv: this.registerForm.value.priv
    }


    this.contService.getstore(par).subscribe(
      (res: any[]) => {
        // Update the list of cars
        this.conts = res;

        // Inform the user
        this.success = "Created successfully";

        //  console.log("OK");
        // Reset the form
        // f.reset();
      },
      (err) => (this.error = err)
    );
  }
}
