import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent} from './login/login.component';
//import { AuthguardGuard } from './authguardguard';
import { AppComponent } from './app.component';
import { AuthguardGuard } from './authguard.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ListamanuComponent } from './dashboard/listamanu/listamanu.component';
import { ListabolloComponent } from './dashboard/listabollo/listabollo.component';
import { ListarevComponent } from './dashboard/listarev/listarev.component';
import { RegmanuComponent } from './dashboard/regmanu/regmanu.component';
import { RegbolloComponent } from './dashboard/regbollo/regbollo.component';
import { RegrevComponent } from './dashboard/regrev/regrev.component';
import { UpdatemanuComponent } from './dashboard/updatemanu/updatemanu.component';


const routes: Routes = [
  //{ path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'manu', component: ListamanuComponent },
  { path: 'bollo', component: ListabolloComponent },
  { path: 'rev', component: ListarevComponent },
  { path: 'regmanu', component: RegmanuComponent },
  { path: 'regbollo', component: RegbolloComponent },
  { path: 'regrev', component: RegrevComponent },
  { path: 'updatemanu', component: UpdatemanuComponent },
  
 // { path: 'registration', component: RegistrationComponent },
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthguardGuard] }
 //{ path: '',   redirectTo: '/home', pathMatch: 'full' } //ROOT DI AVVIO SITO
]



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
