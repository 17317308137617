import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Car } from './car';

@Injectable({
  providedIn: 'root'
})
export class CarService {

  //baseUrl = 'http://localhost/server_api/login8';
  baseUrl = 'https://www.sebastianoweb.it/server_api/backend';
  cars: Car[];

  constructor(private http: HttpClient) { }

  getAll(): Observable<Car[]> {
    return this.http.get(`${this.baseUrl}/list.php`).pipe(
      map((res) => {
        this.cars = res['data'];
        return this.cars;
      }));
  }

  store(car: Car): Observable<Car[]> {
    return this.http.post(`${this.baseUrl}/regmanu.php`, { data: car })
      .pipe(map((res) => {
        this.cars.push(res['data']);
        return this.cars;
      }));
    //  catchError(this.handleError));
}

update(car: Car): Observable<Car[]> {
  return this.http.post(`${this.baseUrl}/update.php`, { data: car })
    .pipe(map((res) => {
      const theCar = this.cars.find((item) => {
        return +item['id'] === +car['id'];
      });
      if (theCar) {
        theCar['marca'] = car['marca'];
        theCar['luogo'] = car['luogo'];
      }
      return this.cars;
    }));
  //  catchError(this.handleError));
}

delete(id: number): Observable<Car[]> {
  const params = new HttpParams()
    .set('id', id.toString());

  return this.http.post(`${this.baseUrl}/delete.php`, { params: params })
    .pipe(map(res => {
      const filteredCars = this.cars.filter((car) => {
        return +car['id'] !== +id;
      });
      return this.cars = filteredCars;
    }));
   // catchError(this.handleError));
}

}
