
export class Car {
  id?: number;
  data_giorno: string;
      data_mese: string;
      data_anno: string;
      marca: string;
      luogo: string;
      km: string;
      tipom: string;
      quale: string;
      quale2: string;
      quale3: string;
      altro: string;
      altro2: string;
      nota: string;
      data = [];
}

// export class Car {
//   constructor(
//  data_giorno: string, data_mese: string, data_anno: string, marca: string, luogo: string, 
//   km: string, tipom: string, quale: string, quale2: string, quale3: string, altro: string, 
//   altro2: string, nota: string,  id?: number, ) {}
// }






// import { Component, OnInit } from '@angular/core';

//  @Component({
//   selector: 'app-car'
//   // templateUrl: './app.component.html',
//   // styleUrls: ['./app.component.css']
//  })

// export class Car  { 
  
// data = [];
//   constructor(
//     data_giorno: string,
//     data_mese: string,
//     data_anno: string,
//     marca: string,
//     luogo: string,
//     km: string,
//     tipom: string,
//     quale: string,
//     quale2: string,
//     quale3: string,
//     altro: string,
//     altro2: string,
//     nota: string,
//     id?:   number
//     ) {

    
//         }
//       }
