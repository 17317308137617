import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Car } from './../../../car';
import { CarService } from './../../../car.service';



@Component({
  selector: 'app-regmanu',
  templateUrl: './regmanu.component.html',
  styleUrls: ['./regmanu.component.css']
})
export class RegmanuComponent implements OnInit {

  cars: Car[];
  //car = new Car('','','','','','','','','','','','','',0);
  //car = new Car({ data_giorno: data_giorno, data_mese: '', data_anno: '', marca: '', luogo: '', km: '', tipom: '', quale: '', quale2: '', quale3: '' , altro: '', altro2: '', nota: '', id: 0 });
  car = new Car();
  error = '';
  success = '';
  data_giorno: string="";
  data_mese: string="";
  data_anno: string="";
  marca: string="";
  luogo: string="";
  km: string="";
  tipom: string="";
  quale: string="";
  quale2: string="";
  quale3: string="";
  altro: string="";
  altro2: string="";
  nota: string="";
  

data = [];
  constructor(
    private carService: CarService,
    private http: HttpClient
    
    ) {}
      
  ngOnInit() {
   // this.getCars();
  }
        
  // getCars(): void {
  //   this.carService.getAll().subscribe(
  //     (res: Car[]) => {
  //       this.cars = res;
  //     },
  //     (err) => {
  //       this.error = err;
  //     }
  //   );
  // }

  addCar() {
    alert("DATI SALVATI CORRETTAMENTE");
    this.error = '';
    this.success = '';

    this.carService.store(this.car).subscribe(
        (res: Car[]) => {
          // Update the list of cars
          this.cars = res;

          // Inform the user
          this.success = 'Created successfully';
          
        //  console.log("OK");
          // Reset the form
        //  f.reset();
        },
        (err) => this.error = err
      );
}
// updateCar(marca, luogo, id) {
//   this.success = '';
//   this.error = '';

//   this.carService.update({marca: marca.value, luogo: luogo.value, id: +id})
//     .subscribe(
//       (res) => {
//         this.cars    = res;
//         this.success = 'Updated successfully';
//       },
//       (err) => this.error = err
//     );
// }




deleteCar(id) {
  this.success = '';
  this.error   = '';
  
  this.carService.delete(+id)
    .subscribe(
      (res: Car[]) => {
        this.cars = res;
        this.success = 'Deleted successfully';
      },
      (err) => this.error = err
    );
}


}

