import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Bollo } from './../../../bollo';
import { BolloService } from './../../../bollo.service';

@Component({
  selector: 'app-regbollo',
  templateUrl: './regbollo.component.html',
  styleUrls: ['./regbollo.component.css']
})
export class RegbolloComponent implements OnInit {

  bollos: Bollo[];
  //bollo = new Bollo('','','','','','');
  bollo = new Bollo();
  error = '';
  success = '';
  data_giorno: string="";
  data_mese: string="";
  data_anno: string="";
  marca: string="";
  prezzo: string="";
  pagato: string="";
  
  

data = [];
  constructor(
    private bolloService: BolloService,
    private http: HttpClient
    
    ) {}
      
  ngOnInit() {
   // this.getBollos();
  }
        
  // getBollos(): void {
  //   this.bolloService.getAll().subscribe(
  //     (res: Bollo[]) => {
  //       this.bollos = res;
  //     },
  //     (err) => {
  //       this.error = err;
  //     }
  //   );
  // }

  addBollo() {
    alert("DATI SALVATI CORRETTAMENTE");
    this.error = '';
    this.success = '';

    this.bolloService.store(this.bollo).subscribe(
        (res: Bollo[]) => {
          // Update the list of cars
          this.bollos = res;

          // Inform the user
          this.success = 'Created successfully';
          
        //  console.log("OK");
          // Reset the form
       //   f.reset();
        },
        (err) => this.error = err
      );
}

// updateCar(name, price, id) {
//   this.success = '';
//   this.error = '';

//   this.carService.update({model: name.value, price: price.value, id: +id})
//     .subscribe(
//       (res) => {
//         this.cars    = res;
//         this.success = 'Updated successfully';
//       },
//       (err) => this.error = err
//     );
// }

deleteCar(id) {
  this.success = '';
  this.error   = '';
  
  this.bolloService.delete(+id)
    .subscribe(
      (res: Bollo[]) => {
        this.bollos = res;
        this.success = 'Deleted successfully';
      },
      (err) => this.error = err
    );
}


}
