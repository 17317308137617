import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Updatemanu } from "./updatemanu";





@Injectable({
  providedIn: 'root'
})
export class UpdatemanuService {

  baseUrl = 'http://localhost/server_api/login8';
  //baseUrl = 'https://www.sebastianoweb.it/server_api/backend';
  updatemanus: Updatemanu[];

  constructor(private http: HttpClient) { }

  getAll(): Observable<Updatemanu[]> {
    return this.http.get(`${this.baseUrl}/list.php`).pipe(
      map((res) => {
        this.updatemanus = res['data'];
        return this.updatemanus;
      }));
  }

update(updatemanu: Updatemanu): Observable<Updatemanu[]> {

  return this.http.post(`${this.baseUrl}/update.php`, { data: updatemanu })
    .pipe(map((res) => {
      const theUpdatemanu = this.updatemanus.find((item) => {
        return +item['id'] === +updatemanu['id'];
      });
      if (theUpdatemanu) {
        theUpdatemanu['marca'] = updatemanu['marca'];
        theUpdatemanu['luogo'] = updatemanu['luogo'];
      }
      return this.updatemanus;
    }));
  //  catchError(this.handleError));
}

delete(id: number): Observable<Updatemanu[]> {
  const params = new HttpParams()
    .set('id', id.toString());
    console.log(id);
  return this.http.delete(`${this.baseUrl}/delete.php`, { params: params })
    .pipe(map(res => {
      const filteredUpdatemanus = this.updatemanus.filter((updatemanu) => {
        return +updatemanu['id'] !== +id;
      });
      return this.updatemanus = filteredUpdatemanus;
      
    }));
  //  catchError(this.handleError));
}






}
