import { Component } from '@angular/core';
//import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Routes, RouterModule } from '@angular/router';
import { DataserviceService } from './../dataservice.service';






@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent  {

  loginbtn:boolean;
  logoutbtn:boolean;
  constructor(private dataService: DataserviceService, private Router: Router) {
    dataService.getLoggedInName.subscribe(name => this.changeName(name));
    if(this.dataService.isLoggedIn())
    {
      console.log("loggedin");
      this.loginbtn=false;
      this.logoutbtn=true
    }
    else{
     this.loginbtn=true;
     this.logoutbtn=false
    }
 
 
}
private changeName(name: boolean): void {
  this.logoutbtn = name;
  this.loginbtn = !name;
}
logout()
{
  this.dataService.deleteToken();
  window.location.href = window.location.href;
}

manu()
{
  //this.Router.navigate(['/manu-detail'], { queryParams: this.id, skipLocationChange: true});
  this.Router.navigate(['/manu']);
  
}

bollo()
{
  this.Router.navigate(['/bollo']);
 
}

rev()
{
  this.Router.navigate(['/rev']);
 
}

cont()
{
  this.Router.navigate(['/cont']);
 
}

}

