import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Rev } from './../../../rev';
import { RevService } from './../../../rev.service';
//import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-regrev',
  templateUrl: './regrev.component.html',
  styleUrls: ['./regrev.component.css']
})
export class RegrevComponent implements OnInit {
 // registerForm: FormGroup;
  submitted = false;




  revs: Rev[];
  //rev = new Rev(0,'','','','','','','');
  rev = new Rev();
  error = '';
  success = '';
  data_giorno: string="";
  data_mese: string="";
  data_anno: string="";
  marca: string="";
  prezzo: string="";
  pagato: string="";
  prossimarev: string="";
  

data = [];
  constructor(
    private revService: RevService,
    private http: HttpClient,
   // private formBuilder: FormBuilder,
    
    ) {}
      
  ngOnInit() {
   // this.registerForm = this.formBuilder.group({
    //  data_giorno: ['', Validators.required],
   //   data_mese: ['', Validators.required],
     // data_anno: ['', Validators.required]
    //  email: ['', [Validators.required, Validators.email]],
    //  message: ['', Validators.required]
    //  password: ['', [Validators.required, Validators.minLength(6)]]
  //});

  }
        
  // getRevs(): void {
  //   this.revService.getAll().subscribe(
  //     (res: Rev[]) => {
  //       this.revs = res;
  //     },
  //     (err) => {
  //       this.error = err;
  //     }
  //   );
  // }

  addRev() {

    // if (this.registerForm.invalid) {
    //   alert('OBBLIGATORIO, GIORNO,MESE,ANNO !');
    //   return;
 // }

  alert('DATI SALVATI CORRETTAMENTE !!');


    //alert("DATI SALVATI CORRETTAMENTE");
    this.error = '';
    this.success = '';

    this.revService.store(this.rev).subscribe(
        (res: Rev[]) => {
          // Update the list of cars
          this.revs = res;

          // Inform the user
          this.success = 'Created successfully';
          
        //  console.log("OK");
          // Reset the form
         // f.reset();
        },
        (err) => this.error = err
      );
}

// updateCar(name, price, id) {
//   this.success = '';
//   this.error = '';

//   this.carService.update({model: name.value, price: price.value, id: +id})
//     .subscribe(
//       (res) => {
//         this.cars    = res;
//         this.success = 'Updated successfully';
//       },
//       (err) => this.error = err
//     );
// }

deleteRev(id) {
  this.success = '';
  this.error   = '';
  
  this.revService.delete(+id)
    .subscribe(
      (res: Rev[]) => {
        this.revs = res;
        this.success = 'Deleted successfully';
      },
      (err) => this.error = err
    );
}


}
