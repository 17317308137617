import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Bollo } from './bollo';

@Injectable({
  providedIn: 'root'
})
export class BolloService {

  //baseUrl = 'http://localhost/server_api/login8';
  baseUrl = 'https://www.sebastianoweb.it/server_api/backend';
  bollos: Bollo[];

  constructor(private http: HttpClient) { }

  getAll(): Observable<Bollo[]> {
    return this.http.get(`${this.baseUrl}/list.php`).pipe(
      map((res) => {
        this.bollos = res['data'];
        return this.bollos;
      }));
      
  }

  store(bollo: Bollo): Observable<Bollo[]> {
    return this.http.post(`${this.baseUrl}/regbollo.php`, { data: bollo })
      .pipe(map((res) => {
        this.bollos.push(res['data']);
        return this.bollos;
      }));
    //  catchError(this.handleError));
}

update(bollo: Bollo): Observable<Bollo[]> {
  return this.http.post(`${this.baseUrl}/update.php`, { data: bollo })
    .pipe(map((res) => {
      const theBollo = this.bollos.find((item) => {
        return +item['id'] === +bollo['id'];
      });
      if (theBollo) {
        theBollo['price'] = +bollo['price'];
        theBollo['model'] = bollo['model'];
      }
      return this.bollos;
    }));
  //  catchError(this.handleError));
}

delete(id: number): Observable<Bollo[]> {
  const params = new HttpParams()
    .set('id', id.toString());

  return this.http.post(`${this.baseUrl}/delete.php`, { params: params })
    .pipe(map(res => {
      const filteredBollos = this.bollos.filter((bollo) => {
        return +bollo['id'] !== +id;
      });
      return this.bollos = filteredBollos;
    }));
   // catchError(this.handleError));
}

}
