// export class Updatemanu {
//     data = [];
//     constructor(
// { data_giorno, data_mese, data_anno, marca, luogo, km, tipom, quale, quale2, quale3, altro, altro2, nota, id }: 
// { data_giorno: string; data_mese: string; data_anno: string; marca: string; luogo: string; km: string; tipom: string; quale: string; quale2: string; quale3: string; altro: string; altro2: string; nota: string; id?: number; }) {
//     }
// }

// export class Updatemanu  { 
  
//   //data = [];
//     constructor(
//       data_giorno: string, 
//       data_mese: string, 
//       data_anno: string, 
//       marca: string, 
//       luogo: string, 
//      km: string, 
//      tipom: string, 
//      quale: string, 
//      quale2: string, 
//      quale3: string, 
//      altro: string, 
//     altro2: string, 
//     nota: string,
//       id?: number
//       ) {
  
      
//           }
//         }
  
// export class Usermodule {
//   public id: number;
//   public data_giorno: string;
//   public data_mese:string;
//   public data_anno:string;
//   public marca:string;
//   public luogo:string;

//   constructor(id:number,data_giorno: string,data_mese:string,data_anno:string,marca:string,luogo:string) {
//     this.id = id;
//     this.data_giorno = data_giorno;
//     this.data_mese = data_mese;
//     this.data_anno = data_anno;
//     this.marca=marca;
//     this.luogo=luogo;
//   }
// }


export class Updatemanu {
  
  constructor(
    data_giorno: string,
        data_mese: string,
        data_anno: string,
       marca: string,
       luogo: string,
        km: string,
        tipom: string,
        quale: string,
        quale2: string,
        quale3: string,
        altro: string,
        altro2: string,
        nota: string,
    id?:   number) {}
}


// export class Updatemanu {

//     data_giorno: string;
//         data_mese: string;
//         data_anno: string;
//        marca: string;
//        luogo: string;
//         km: string;
//         tipom: string;
//         quale: string;
//         quale2: string;
//         quale3: string;
//         altro: string;
//         altro2: string;
//         nota: string;
//         id?: number;
       
//   }
  
  // export class Updatemanu {
  //   constructor(
  //  data_giorno: string, data_mese: string, data_anno: string, marca: string, luogo: string, 
  //   km: string, tipom: string, quale: string, quale2: string, quale3: string, altro: string, 
  //   altro2: string, nota: string,  id?: number, ) {}
  // }
  
  
  
  
  
  
  
  
//   export class Updatemanu  { 
    
//     data = [];
//     constructor (
      
// { data_giorno, 
//   data_mese, 
//   data_anno, 
//   marca, 
//   luogo, 
//   km, 
//   tipom, 
//   quale, 
//   quale2,
//    quale3, 
//    altro, 
//    altro2, 
//    nota, 
//    id }: { data_giorno: string; 
//     data_mese: string; 
//     data_anno: string; 
//     marca: string; 
//     luogo: string; 
//     km: string; 
//     tipom: string;
//      quale: string; 
//      quale2: string; 
//      quale3: string;
//       altro: string; 
//       altro2: string;
//        nota: string;
//         id: number; },
      
      
//       ) {
  
      
//           }
//         }
  