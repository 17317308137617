import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Rev } from './rev';


@Injectable({
  providedIn: 'root'
})
export class RevService {

  //baseUrl = 'http://localhost/server_api/login8';
  baseUrl = 'https://www.sebastianoweb.it/server_api/backend';
  revs: Rev[];

  constructor(private http: HttpClient) { }

  // getAll(): Observable<Rev[]> {
  //   return this.http.get(`${this.baseUrl}/list.php`).pipe(
  //     map((res) => {
  //       this.revs = res['data'];
  //       return this.revs;
  //     }));
      
  // }

  store(rev: Rev): Observable<Rev[]> {
    return this.http.post(`${this.baseUrl}/regrev.php`, { data: rev })
      .pipe(map((res) => {
        this.revs.push(res['data']);
        return this.revs;
      }));
    //  catchError(this.handleError));
}

update(rev: Rev): Observable<Rev[]> {
  return this.http.post(`${this.baseUrl}/update.php`, { data: rev })
    .pipe(map((res) => {
      const theRev = this.revs.find((item) => {
        return +item['id'] === +rev['id'];
      });
      if (theRev) {
        theRev['price'] = +rev['price'];
        theRev['model'] = rev['model'];
      }
      return this.revs;
    }));
  //  catchError(this.handleError));
}

delete(id: number): Observable<Rev[]> {
  const params = new HttpParams()
    .set('id', id.toString());

  return this.http.post(`${this.baseUrl}/delete.php`, { params: params })
    .pipe(map(res => {
      const filteredRevs = this.revs.filter((rev) => {
        return +rev['id'] !== +id;
      });
      return this.revs = filteredRevs;
    }));
   // catchError(this.handleError));
}

}
