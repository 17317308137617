
export class Cont {
  id: number;
    nome: string;
    email: string;
    message: string;
    priv: string;
   
}


// import { Component } from '@angular/core';

//  @Component({
//   selector: 'app-cont'
//   // templateUrl: './app.component.html',
//   // styleUrls: ['./app.component.css']
//  })

// export class Cont  { 
  
// data = [];


//   constructor(
//     nome: string,
//     email: string,
//     message: string,
//     priv: string,
//     id?:   string
//     ) {}
     
//   }
