import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Updatemanu } from './../../../updatemanu';
import { UpdatemanuService } from './../../../updatemanu.service';
import { ActivatedRoute, Router } from '@angular/router';
//import { FormGroup,  FormBuilder,  Validators } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';


@Component({
  selector: 'app-updatemanu',
  templateUrl: './updatemanu.component.html',
  styleUrls: ['./updatemanu.component.css']
})


export class UpdatemanuComponent implements OnInit {
  
   //sorting
   
  


  // updatemanus: any[] = [
  //   { name: 'G' },
  //   { name: 'B' },
  //   { name: 'Z' },
  //   { name: 'A' },
  //   { name: 'H' }
  // ];

  // data_giorno: string="";
  // data_mese: string="";
  // data_anno: string="";
  // marca: string=""; 
  //     luogo: string=""; 
  //    km: string=""; 
  //    tipom: string=""; 
  //    quale: string=""; 
  //    quale2: string=""; 
  //    quale3: string=""; 
  //    altro: string=""; 
  //   altro2: string=""; 
  //   nota: string="";


  //updatemanus: Updatemanu[];  // QUESTO PER LOCALE
  
  error = '';
  success = '';
  updatemanus = new Updatemanu('','','','','','','','','','','','','', 0);  // QUESTO PER PRODUZIONE
       
//data = [];
config: any;
collection = [];
  constructor(
    private route: ActivatedRoute,
    private updatemanuService: UpdatemanuService,
    private http: HttpClient, 
    private activeAouter: ActivatedRoute, 
    private router: Router
    
    ) {
     // PAGINATION
     this.config = {
      currentPage: 1,
      itemsPerPage: 15,
      totalItems:0
      };
      route.queryParams.subscribe(
      params => this.config.currentPage= params['page']?params['page']:1 );
       
      for (let i = 1; i <= 100; i++) {
      this.collection.push('item ${i}');
      }
       
      }
       
      pageChange(newPage: number) {
          this.router.navigate(['updatemanu'], { queryParams: { page: newPage } });
        
     

     // FINE PAGINATION

    }
      
  ngOnInit() {
    this.getUpdatemanus();
   
}
  
        
  getUpdatemanus(): void {
    this.updatemanuService.getAll().subscribe(
      (res: Updatemanu[]) => {
        this.updatemanus = res;
      },
      (err) => {
        this.error = err;
      }
    );
  }

//   addCar(f) {
//     this.error = '';
//     this.success = '';

//     this.carService.store(this.car).subscribe(
//         (res: Car[]) => {
//           // Update the list of cars
//           this.cars = res;

//           // Inform the user
//           this.success = 'Created successfully';

//           // Reset the form
//           f.reset();
//         },
//         (err) => this.error = err
//       );
// }

updateUpdatemanu(name, luogo, id) {
  alert("DATO AGGIORNATO");
  //console.log(luogo);
  this.success = '';
  this.error = '';

  this.updatemanuService.update({ marca: name.value, luogo: luogo.value, id: +id })
    .subscribe(
      (res) => {
        this.updatemanus    = res;
        this.success = 'Updated successfully';
        
      },
      (err) => this.error = err
    );
    
}

deleteUpdatemanu(id) {
  alert("DATO ELIMINATO");
  this.success = '';
  this.error   = '';
  
  this.updatemanuService.delete(+id)
    .subscribe(
      (res: Updatemanu[]) => {
        this.updatemanus = res;
        this.success = 'Deleted successfully';
      },
      (err) => this.error = err
    );
}



}

