

export class Rev {
  id: number;
  data_giorno: string;
  data_mese: string;
  data_anno: string;
  marca: string;
  prezzo: string;
  pagato: string;
  prossimarev: string;
    data = [];
}



// export class Rev {
//   public Id: number;
//   public data_giorno: string;
//   public data_mese: string;
//   public data_anno: string;
//   public marca: string;
//   public prezzo: string;
//   public pagato: string;
//   public prossimarev: string;

//   data = [];
//   constructor(Id:number,data_giorno: string,data_mese:string,data_anno:string,marca:string,prezzo:string,pagato:string,prossimarev:string) {
//     this.Id = Id;
//     this.data_giorno = data_giorno;
//     this.data_mese = data_mese;
//     this.data_anno = data_anno;
//     this.marca=marca;
//     this.prezzo=prezzo;
//     this.pagato=pagato;
//     this.prossimarev=prossimarev;
//   }
// }
















// import { Component } from '@angular/core';

//  @Component({
//   selector: 'app-rev'
//   // templateUrl: './app.component.html',
//   // styleUrls: ['./app.component.css']
//  })

// export class Rev  { 
  
// data = [];
//   constructor(
//     data_giorno: string,
//     data_mese: string,
//     data_anno: string,
//     marca: string,
//     prezzo: string,
//     pagato: string,
//     prossimarev: string,
//     id?:   number
//     ) {

    
//         }
//       }
